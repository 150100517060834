import { Trans } from 'next-i18next'
import Button from '../../base/button/Button'
import Link from 'next/link'
import SearchTextField from '../search/SearchTextField'

const AppBarMenu = () => {
    return (
        <>
            <div className='items-center flex space-x-4 mr-3 xl:mr-0'>
                <Button as={Link} href='/app/administration/demand/create'>
                    <Trans i18nKey='dashboard.shared.appbar.new_demand' />
                </Button>
                <SearchTextField />
            </div>
        </>
    )
}

export default AppBarMenu

import { AxiosError } from 'axios'
import { Order, OrderItem } from '../../../../services/api/order/OrderService.types'
import { UseOrderCreateProps, UserOrderCreateInternalRequest } from '../useOrder.types'
import {
    createOrder,
    createOrderItem,
    refreshOrderItem,
    updateOrderItem
} from '../../../../services/api/order/OrderService'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import useAxios from '../../../useAxios'

export const CREATE_ORDER_ITEM_KEY = 'createOrderItem'

const useOrderItemCreate = ({ onSuccess, onError }: UseOrderCreateProps = {}) => {
    const axiosInstance = useAxios()
    const queryClient = useQueryClient()

    const { replace } = useRouter()

    return useMutation<OrderItem, AxiosError, UserOrderCreateInternalRequest>(
        [CREATE_ORDER_ITEM_KEY],
        async ({ onlyRefresh, productId, orderId, orderItemId, userId, data }) => {
            // Update order item section
            if (!!orderItemId) {
                if (onlyRefresh && !!productId) {
                    return refreshOrderItem(axiosInstance, productId, data, true)
                }
                return updateOrderItem(axiosInstance, orderId, orderItemId, data, true)
            }

            // Create order item section
            if (onlyRefresh && !!productId) {
                return refreshOrderItem(axiosInstance, productId, undefined, !!orderId)
            }
            if (!orderId) {
                // Create a new order
                const newOrder = await createOrder(axiosInstance, {
                    idProduct: productId,
                    idUser: userId,
                    orderItem: data
                })
                await replace({ pathname: `/app/administration/demand/create/${newOrder.id}`, query: { step: 2 } })
                return
            }
            return createOrderItem(axiosInstance, orderId, data, true)
        },
        {
            onSuccess: (data, { orderId, orderItemId, onlyRefresh }) => {
                onSuccess?.(data)
                if (onlyRefresh) {
                    return
                }

                // Update order item
                if (orderItemId) {
                    queryClient.setQueriesData<Order>(['order', orderId], prev => {
                        return {
                            ...prev,
                            orderItems: prev.orderItems.map(item => {
                                return item.id === orderItemId ? data : item
                            })
                        }
                    })
                    return
                }

                // Create order item
                queryClient.setQueriesData<Order>(['order', orderId], prev => {
                    return {
                        ...prev,
                        orderItems: [...prev.orderItems, data]
                    }
                })
            },
            onError
        }
    )
}

export default useOrderItemCreate

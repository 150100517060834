import { BadgeColor, BadgeVariant } from './Badge'

type BadgeConfig = Record<BadgeColor, Record<BadgeVariant, string>>

export const badgeConfig: BadgeConfig = {
    generic: {
        normal: 'text-secondary bg-gray-330',
        outlined: ''
    },
    primary: {
        normal: 'text-primary bg-primary bg-opacity-20',
        outlined: 'text-primary'
    },
    success: {
        normal: 'text-success bg-success-100',
        outlined: 'text-success'
    },
    secondary: {
        normal: 'text-secondary bg-secondary-100',
        outlined: 'text-secondary'
    },
    info: {
        normal: 'text-info bg-info-100',
        outlined: 'text-info'
    },
    warning: {
        normal: 'text-warning bg-warning-100',
        outlined: 'text-warning'
    },
    danger: {
        normal: 'text-danger bg-danger-100',
        outlined: 'text-danger'
    },
    gray: {
        normal: 'text-gray bg-gray-100',
        outlined: 'text-gray'
    },
    blue: {
        normal: 'text-blue bg-blue-100',
        outlined: 'text-blue'
    },
    red: {
        normal: 'text-red bg-red-100',
        outlined: 'text-red'
    },
    grey: {
        normal: 'text-grey bg-grey-100',
        outlined: 'text-grey'
    },
    green: {
        normal: 'text-green bg-green-100',
        outlined: 'text-green'
    },
    orange: {
        normal: 'text-orange bg-orange-100',
        outlined: 'text-orange'
    },
    'active-green': {
        normal: 'text-green-175 bg-green-150 bg-opacity-40',
        outlined: 'text-green-175'
    },
    'inactive-red': {
        normal: 'text-red-250 bg-red-50',
        outlined: 'text-red-250'
    },
    'inactive-gray': {
        normal: 'text-gray-490 bg-gray-470 bg-opacity-40',
        outlined: 'text-gray-490'
    },
    'light-blue': {
        normal: 'text-blue-200 bg-blue-100',
        outlined: 'text-blue-200'
    },
    'select-blue': {
        normal: 'text-grey-700 bg-blue-100',
        outlined: 'text-blue-200'
    },
    purple: {
        normal: 'text-purple bg-purple-100',
        outlined: 'text-purple'
    },
    'action-green': {
        normal: 'text-gray-600 bg-green-100',
        outlined: ''
    },
    'action-red': {
        normal: 'text-gray-600 bg-red-50',
        outlined: ''
    },
    'action-orange': {
        normal: 'text-gray-600 bg-orange-100',
        outlined: ''
    },
    'action-blue': {
        normal: 'text-gray-600 bg-gray-330',
        outlined: ''
    }
}

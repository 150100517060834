import { DEFAULT_URL } from '../config/config'
import { useCurrentUser } from '../auth/auth'
import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'

const DASHBOARD_PAGE = DEFAULT_URL
const LOGIN_PAGE = '/'

const ONLY_LOGGED_MAPPING = {
    false: DASHBOARD_PAGE,
    true: LOGIN_PAGE
}

const useMiddleware = (onlyLogged = true, redirect: boolean = true) => {
    const { isReady: isRouterReady, replace, asPath } = useRouter()
    const { isLogged, isReady } = useCurrentUser()
    const [allowed, setAllowed] = useState<boolean>(true)
    const [isInitialized, setIsInitialized] = useState<boolean>(false)

    useEffect(() => {
        if (!isReady || !isRouterReady) {
            return
        }

        const disallow = isLogged !== onlyLogged
        setAllowed(!disallow)
        if (disallow && redirect) {
            replace(ONLY_LOGGED_MAPPING[`${onlyLogged}`]).finally(() => {
                return setIsInitialized(true)
            })
            return
        }

        setIsInitialized(true)
    }, [isLogged, onlyLogged, replace, isReady, isRouterReady, asPath, redirect])

    return {
        isInitialized,
        allowed
    }
}

export default useMiddleware

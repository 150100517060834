import { LogoutIcon, UserIcon } from '@heroicons/react/outline'
import { PersonIcon } from '../../base/icon/Person'
import { Trans } from 'next-i18next'
import { useCurrentUser, useSignOut } from '../../../auth/auth'
import { useQueryClient } from '@tanstack/react-query'
import Button from '../../base/button/Button'
import Dropdown from '../../base/dropdown/Dropdown'
import DropdownItem from '../../base/dropdown/DropdownItem'
import Link from 'next/link'

const AppBarUserMenu = () => {
    const { user } = useCurrentUser()
    const { signOut } = useSignOut()
    const queryClient = useQueryClient()

    const handleLogout = () => {
        queryClient.cancelQueries()
        signOut()
    }

    const getTitle = () => {
        if (!user) {
            return
        }
        const { role, billingAddressCompanyName, firstName, lastName } = user
        if (role === 'customer') {
            return (
                <p className='text-sm font-semibold bg-gradient-to-r from-primary to-primary-200 bg-clip-text text-transparent'>
                    {billingAddressCompanyName}
                </p>
            )
        }
        const fullName = `${firstName} ${lastName}`
        return (
            <p className='text-sm font-semibold bg-gradient-to-r from-primary to-primary-200 bg-clip-text text-transparent'>
                {fullName}
            </p>
        )
    }

    return (
        <div className='flex items-center'>
            <Dropdown
                placement='bottom-end'
                activator={(props, ref) => {
                    return (
                        <Button icon {...props} ref={ref} size='icon' className='h-[38px] w-[38px]'>
                            <PersonIcon />
                        </Button>
                    )
                }}
            >
                <div className='py-2.5 shadow-primary-card rounded-[10px]'>
                    <div className='flex flex-col items-start px-3 pb-2 mb-1 border-b-2'>
                        {user?.role && <p className='uppercase text-[8px] font-medium text-grey-350'>{user.role}</p>}
                        {getTitle()}
                    </div>
                    <DropdownItem
                        as={Link}
                        href='/app/administration/profile'
                        className='px-3 !text-black'
                        icon={<UserIcon />}
                    >
                        <Trans i18nKey='appbar.user.profile' />
                    </DropdownItem>
                    <DropdownItem onClick={handleLogout} className='px-3 !text-black' icon={<LogoutIcon />}>
                        <Trans i18nKey='appbar.user.logout' />
                    </DropdownItem>
                </div>
            </Dropdown>
        </div>
    )
}

export default AppBarUserMenu

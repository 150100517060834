import { Order } from '../../../../services/api/order/OrderService.types'
import { Trans } from 'next-i18next'
import Badge, { BadgeProps } from '../../../base/badge/Badge'
import React from 'react'

export type DemandStatusBadgeProps = Omit<BadgeProps, 'color'> & {
    status?: Order['status']
}

const DemandStatusBadge = ({ status, ...props }: DemandStatusBadgeProps) => {
    switch (status.key) {
        case 'draft':
            return (
                <Badge color='orange' {...props}>
                    <Trans i18nKey='demand.status.unfinished' />
                </Badge>
            )
        case 'finished':
            return (
                <Badge color='light-blue' {...props}>
                    <Trans i18nKey='demand.status.filled' />
                </Badge>
            )
        default:
            return <Badge {...props}>{status.label}</Badge>
    }
}

export default DemandStatusBadge

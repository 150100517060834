import { format as extFormatPhoneNumber, parse as extParsePhoneNumber } from 'libphonenumber-js'

/*

Generate random string

More info: https://stackoverflow.com/questions/1349404/generate-random-string-characters-in-javascript

 */
export const generateRandomString = (length: number, withCharacters = true, withNumbers = true) => {
    if (!withCharacters && !withNumbers) {
        return undefined
    }
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
    const numbers = '0123456789'
    let result = ''
    const allCharacters = `${withCharacters ? characters : ''}${withNumbers ? numbers : ''}`
    const charactersLength = allCharacters.length
    for (let i = 0; i < length; i++) {
        result += allCharacters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
}

export const camelCaseToKebabCase = (str: string) => {
    return str.replace(/[A-Z]+(?![a-z])|[A-Z]/g, ($, ofs) => {
        return (ofs ? '-' : '') + $.toLowerCase()
    })
}

export const capitalizeFirstLetter = (text: string) => {
    return text.charAt(0).toUpperCase() + text.slice(1)
}

/*

Get function name

More info: https://gist.github.com/dfkaye/6384439

 */
export const getFunctionName = (fun: any) => {
    if (!fun) {
        return undefined
    }
    if (fun?.displayName) {
        return fun.displayName
    }
    fun = Object(fun)
    const F = typeof fun == 'function'
    const N = fun.name
    const S = F && ((N && ['', N]) || fun.toString().match(/function ([^\(]+)/))
    return (!F && 'not a function') || (S && S[1]) || 'anonymous'
}

export const isStringInteger = (str: string): boolean => {
    str = str.trim()
    if (!str) {
        return false
    }
    str = str.replace(/^0+/, '') || '0'
    const n = Math.floor(Number(str))
    return n !== Infinity && String(n) === str && n >= 0
}

export const truncateStringWithData = (text: string, length: number) => {
    if (!text) {
        return {
            text,
            truncated: false
        }
    }
    if (text.length < length) {
        return {
            text,
            truncated: false
        }
    }
    return {
        text: `${text.substring(0, length)}...`,
        truncated: true
    }
}

export const truncateString = (text: string, length: number) => {
    return truncateStringWithData(text, length).text
}

export const formatAddress = (street?: string, city?: string, zip?: string, name?: string, country?: string) => {
    let address = [street, city, zip, country].filter(item => item && item.length).join(', ')

    if (name?.length) {
        if (address?.length) {
            address = `${name} - ${address}`
        } else {
            address = name
        }
    }

    return address
}

export const replaceAllDiacriticsFromText = (text?: string) => {
    return text?.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

export const makeAttributeKeyValue = (keyName?: string) => {
    return replaceAllDiacriticsFromText(keyName)?.toLowerCase().trim()
}

export const formatPhoneNumber = (phoneNumber: string) => {
    return extFormatPhoneNumber(extParsePhoneNumber(phoneNumber), 'INTERNATIONAL')
}

export const formatPhoneNumberCustom = (phone: string): string => {
    const cleaned = phone.replace(/\D/g, '')

    const truncated = cleaned.slice(0, 18)

    let formatted = ''

    const prefix = truncated.match(/^\d{2,3}/)?.[0] || ''
    const remaining = truncated.slice(prefix.length)

    formatted = `+${prefix}`

    if (remaining.length) {
        formatted += ` ${remaining.match(/.{1,3}/g)?.join(' ')}`
    }

    return formatted.trim()
}

export const removePTags = (input: string): string => {
    const pTagPattern = /<\/?p>/gi
    return input.replace(pTagPattern, '')
}

export const formatTranslationKey = (key: string): string => {
    if (key.length <= 33) {
        return key
    }
    const cleanedKey = key.replace(/^frontend\./, '')
    const parts = cleanedKey.split('.')
    const firstPart = parts.slice(0, 2).join('.')
    const lastValue = key.slice(-8)
    return `${firstPart} ... ${lastValue}`
}

/**
 * Formats a text string based on the number of displayed items and whether to show all items.
 *
 * @param config Configuration object containing the following properties:
 * - `items` (string[]): Array of items to be formatted.
 * - `showAll` (boolean): Determines whether to display all items.
 * - `limit` (number): Number of items to display if `showAll` is false. Defaults to 3.
 * @returns A formatted string.
 */
export const formatDisplayedItems = ({
    items,
    showAll,
    limit = 3
}: {
    items: string[]
    showAll: boolean
    limit?: number
}): string => {
    const displayedItems = showAll ? items : items.slice(0, limit)
    return showAll ? displayedItems.join(', ') : `${displayedItems.join(', ')}${items.length > limit ? ', ...' : ''}`
}

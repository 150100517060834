import { DEFAULT_URL } from '../../../config/config'
import Link from 'next/link'
import useTheme from '../../../hooks/useTheme'

const SidebarHeader = () => {
    const { getPageImage } = useTheme()

    return (
        <div className='flex flex-shrink-0 py-7 justify-center border-b-2 mx-6'>
            <Link href={DEFAULT_URL}>
                <img className='w-48' src={`${getPageImage('logo.png')}`} alt='Workflow' />
            </Link>
        </div>
    )
}

export default SidebarHeader

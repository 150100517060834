/* eslint-disable max-len */
export type ButtonVariant =
    | 'primary'
    | 'primary-small'
    | 'secondary'
    | 'secondary-small'
    | 'tertiary'
    | 'tertiary-transparent'
    | 'tertiaryV2'
    | 'disabled'
    | 'disabled-small'
    | 'destructive'
    | 'invert'
    | 'outline'
    | 'unstyled'
    | 'rounded-icon'

export type ButtonType = 'normal' | 'text'
export type ButtonSize = 'tiny' | 'sm' | 'new-sm' | 'md' | 'lg' | 'icon' | 'icon-small' | 'icon-medium' | 'tiny-icon'

type ButtonConfig = {
    sizes: {
        button: Record<ButtonSize, string>
        icon: Record<ButtonSize, string>
    }
    variant: Record<ButtonVariant, Record<ButtonType, string>>
}

export const buttonConfig: ButtonConfig = {
    sizes: {
        button: {
            tiny: 'px-2 text-xs font-normal leading-6 rounded-md',
            'tiny-icon': 'px-1 text-xs font-normal leading-6 rounded-md',
            sm: 'text-sm font-normal px-3.5 pt-2 pb-2.5 rounded-lg leading-5',
            'new-sm': 'py-1.5 px-1.5 text-sm font-normal rounded-lg',
            md: 'pt-2 pb-2.5 px-3.5 text-sm font-normal rounded-lg',
            lg: 'pt-2 pb-2.5 px-3.5 text-base font-normal rounded-lg',
            icon: 'text-xs font-normal leading-8 p-2.5 rounded-lg',
            'icon-medium': 'text-xs font-normal p-1.5 rounded-md text-base',
            'icon-small': 'text-xs font-normal p-1 rounded-md'
        },
        icon: {
            tiny: 'h-2 w-2',
            'tiny-icon': 'h-6 w-4',
            sm: 'h-4 w-4',
            'new-sm': 'h-4 w-4',
            md: 'h-4 w-4',
            lg: 'h-4 w-4',
            icon: 'h-5 w-5',
            'icon-medium': 'h-5 w-5',
            'icon-small': 'h-4 w-4'
        }
    },
    variant: {
        primary: {
            normal: 'bg-primary text-white border-none transition-all duration-200 ease justify-center items-center shadow-button-primary hover:opacity-80 active:transform active:translate-x-[2px] active:translate-y-[2px]',
            text: ''
        },
        'primary-small': {
            normal: 'bg-primary text-white border-none transition-all duration-200 ease justify-center items-center shadow-button-small hover:opacity-80 active:transform active:translate-x-[2px] active:translate-y-[2px]',
            text: ''
        },
        secondary: {
            normal: 'bg-gray text-white border-none transition-all duration-200 ease justify-center items-center shadow-button-primary hover:opacity-80 active:transform active:translate-x-[2px] active:translate-y-[2px]',
            text: ''
        },
        'secondary-small': {
            normal: 'bg-gray text-white border-none transition-all duration-200 ease justify-center items-center shadow-button-small hover:opacity-80 active:transform active:translate-x-[2px] active:translate-y-[2px]',
            text: ''
        },
        destructive: {
            normal: 'bg-white text-primary border border-primary hover:bg-primary hover:bg-opacity-5',
            text: ''
        },
        tertiary: {
            normal: 'bg-white text-secondary border border-secondary transition-all duration-200 ease justify-center items-center hover:bg-gray hover:bg-opacity-5',
            text: ''
        },
        'tertiary-transparent': {
            normal: 'bg-none text-secondary border border-secondary transition-all duration-200 ease justify-center items-center hover:bg-gray hover:bg-opacity-5',
            text: ''
        },
        tertiaryV2: {
            normal: 'bg-none text-secondary border-none transition-all duration-200 ease justify-center items-center shadow-button-primary active:transform active:translate-x-[2px] active:translate-y-[2px]',
            text: ''
        },
        disabled: {
            normal: 'bg-gray-350 text-gray-650 border border-gray-550 cursor-not-allowed shadow-button-disabled',
            text: ''
        },
        'disabled-small': {
            normal: 'bg-gray-350 text-gray-650 border border-gray-550 cursor-not-allowed shadow-button-small',
            text: ''
        },
        invert: {
            normal: 'bg-inherit rounded-md text-white border border-white',
            text: ''
        },
        outline: {
            normal: 'bg-white text-primary border border-primary hover:bg-primary hover:bg-opacity-5',
            text: ''
        },
        unstyled: {
            normal: 'bg-none border-none',
            text: ''
        },
        'rounded-icon': {
            normal: 'bg-none text-primary border-none rounded-full',
            text: ''
        }
    }
}
